<template>
  <!-- 开票项目管理 -->
  <div class="projectAdmin">
    <div class="btn-content">
      <el-button type="primary" size="small" @click="addBtn">添加</el-button>
    </div>
    <Table
      :table-data="tableData"
      :title-name="titleName"
      :operation="true"
      :col-class-type="true"
    >
      <template slot-scope="scope">
        <div>
          <el-button
            type="text"
            size="small"
            sort="primary"
            @click="updateFun(scope.scopeRow)"
            >编辑</el-button
          >
          <el-button
            type="text"
            size="small"
            sort="danger"
            @click="deleteFun(scope.scopeRow)"
            >删除</el-button
          >
        </div>
      </template>
    </Table>
    <charterPopup
      v-if="show"
      :header-text="headerText"
      @confirmFun="confirmFun"
      @closePopupFun="clearForm"
    >
      <el-form
        ref="Form"
        :model="Form"
        :rules="rules"
        label-width="110px"
        label-position="left"
        class="demo-form dialog-form"
      >
        <el-form-item label="业务类型" prop="businessType">
          <el-select
            v-model="Form.businessType"
            clearable
            placeholder="请选择业务类型"
          >
            <el-option
              v-for="role in businessType"
              :key="role.value"
              :value="role.value"
              :label="role.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发票行性质" prop="invoiceLines">
          <el-select
            v-model="Form.invoiceLines"
            clearable
            placeholder="请选择发票行性质"
          >
            <el-option
              v-for="role in invoceType"
              :key="role.value"
              :value="role.value"
              :label="role.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商品编码" prop="goodsCode">
          <el-input
            v-model="Form.goodsCode"
            :maxlength="20"
            :minlength="19"
            placeholder="请输入商品编码"
          ></el-input>
        </el-form-item>
        <el-form-item label="项目名称" prop="name">
          <el-input
            v-model="Form.name"
            clearable
            placeholder="请输入项目名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="计量单位" prop="unit">
          <el-input v-model="Form.unit" placeholder="次"></el-input>
        </el-form-item>
        <el-form-item label="规格型号">
          <el-select
            v-model="Form.specModel"
            clearable
            placeholder="请选择规格型号"
          >
            <el-option
              v-for="role in specModel"
              :key="role.value"
              :label="role.label"
              :value="role.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="打印备注">
          <el-select v-model="Form.remark" clearable placeholder="请选择备注">
            <el-option
              v-for="role in remark"
              :key="role.value"
              :label="role.label"
              :value="role.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </charterPopup>
  </div>
</template>

<script>
import {
  openInvoceListAPI,
  updateOrAddInvoceAPI,
  deleteInvoceAPI,
} from "@/api/lib/api.js";
export default {
  data() {
    return {
      id: "",
      tableData: [],
      titleName: [
        {
          title: "业务类型",
          props: "businessType",
          SpecialJudgment: (res) => {
            return res == 2
              ? "汽车订票"
              : res == 1
              ? "定制客运"
              : res == 6
              ? "定制班线"
              : res == 100
              ? "购票服务费"
              : res == 4
              ? "包车"
              : "";
          },
        },
        {
          title: "发票行性质",
          props: "invoiceLines",
          SpecialJudgment: (res) => {
            return res == 0 ? "正常行" : res == 1 ? "折扣行" : "被折扣行";
          },
        },
        {
          title: "商品编码",
          props: "goodsCode",
        },
        {
          title: "项目名称",
          props: "name",
        },
        {
          title: "计量单位",
          props: "unit",
        },
        {
          title: "规格型号",
          props: "specModel",
          SpecialJudgment: (res) => {
            return res == "{起点站}-{终点站}" ? "{起点站}-{终点站}" : "";
          },
        },
        {
          title: "打印备注",
          props: "remark",
          SpecialJudgment: (res) => {
            return res == "班次时间：{发车时间}\n{用户备注}"
              ? "班次时间：{发车时间}\n{用户备注}"
              : "";
          },
        },
        {
          title: "启用",
          props: "status",
          render: (h, params, index) => {
            return h("el-switch", {
              props: {
                value: params.status == 0 ? true : false,
                "active-color": "#13ce66",
                "inactive-color": "#E3E3E3",
              },
              on: {
                change: (val) => {
                  let obj = {
                    id: params.id,
                    status: params.status == 0 ? 1 : 0,
                  };
                  this.updateOrAddInvoce(obj);
                },
              },
            });
          },
        },
      ],
      headerText: "",
      show: false,
      businessType: [
        {
          value: 4,
          label: "包车",
        },
        {
          value: 2,
          label: "汽车订票",
        },
        {
          value: 1,
          label: "定制客运",
        },
        {
          value: 6,
          label: "定制班线",
        },
        {
          value: 100,
          label: "购票服务费",
        },
      ],
      invoceType: [
        {
          value: 0,
          label: "正常行",
        },
        {
          value: 1,
          label: "折扣行",
        },
        {
          value: 2,
          label: "被折扣行",
        },
      ],
      specModel: [
        {
          value: "{起点站}-{终点站}",
          label: "{起点站}-{终点站}",
        },
      ],
      remark: [
        {
          value: "班次时间：{发车时间}\n{用户备注}",
          label: "班次时间：{发车时间}\n{用户备注}",
        },
      ],
      Form: {
        invoiceLines: "",
        businessType: "",
        goodsCode: "",
        name: "",
        unit: "",
        remark: null,
        specModel: null,
      },
      rules: {
        businessType: [
          {
            required: true,
            message: "请输入必填项",
            trigger: "blur",
          },
        ],
        goodsCode: [
          {
            required: true,
            trigger: "change",
            validator: (rule, value, callback) => {
              if (value === "") {
                callback("请输入商品编码");
              } else {
                if (/[^\d]/.test(value)) {
                  callback("请输入数字");
                }
                if (value.toString().length < 19) {
                  callback("商品编码至少19位");
                } else {
                  callback();
                }
              }
            },
          },
        ],
        name: [
          {
            required: true,
            message: "请输入必填项",
            trigger: "blur",
          },
        ],
        invoiceLines: [
          {
            required: true,
            message: "请输入必填项",
            trigger: "blur",
          },
        ],
        unit: [
          {
            required: true,
            message: "请输入必填项",
            trigger: "blur",
          },
        ],
        remark: [
          {
            required: true,
            message: "请输入必填项",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    updateFun(val) {
      this.headerText = "编辑";
      this.show = true;
      this.id = val.id;
      this.Form = this.deepClone(val);
      // this.Form.invoiceLines = val.invoiceLines;
    },
    renderData() {
      openInvoceListAPI().then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData = res.data;
        }
      });
    },
    updateOrAddInvoce(obj) {
      updateOrAddInvoceAPI(obj).then((res) => {
        if (res.code == "SUCCESS") {
          this.renderData();
        }
      });
    },
    deleteFun(val) {
      this.$confirm("确定删除吗？", "提示").then(() => {
        deleteInvoceAPI({ id: val.id }).then((res) => {
          if (res.code == "SUCCESS") {
            this.renderData();
          }
        });
      });
    },
    addBtn() {
      this.headerText = "添加";
      this.show = true;
      this.Form = {};
    },
    clearForm() {
      this.show = false;
    },
    confirmFun() {
      this.show = false;
      this.$refs.Form.validate((valid) => {
        if (valid) {
          if (this.headerText == "编辑") {
            let obj1 = this.Form;
            obj1.id = this.id;
            console.log(obj1);
            updateOrAddInvoceAPI(obj1).then((res) => {
              if (res.code == "SUCCESS") {
                this.renderData();
                this.$message("修改成功");
              }
            });
          } else {
            updateOrAddInvoceAPI(this.Form).then((res) => {
              if (res.code == "SUCCESS") {
                this.renderData();
              }
            });
          }
        }
      });
    },
  },
  mounted() {
    this.renderData();
  },
};
</script>

<style scoped lang="scss">
.projectAdmin {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 4px 20px;
  box-sizing: border-box;
  background: #ffffff;
  .btn-content {
    margin: 10px;
  }
}
</style>
